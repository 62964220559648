<template>
  <div>
    <a-spin :spinning="loading">
      <p class="ui-title">欢迎使用上栗县行政事业性国有资产台账管理系统</p>
      <p class="ui-childTitle">--在建工程管理子系统</p>
      <p class="ui-content">
        上栗县行政事业性国有资产台账管理系统--在建工程管理子系统主要用于管理上栗县政府投资项目在建工程，实现上栗县在建工程台账管理，对在建工程的资金进行全局把控，对在建工程的合规性进行全生命周期监控，对在建工程的数据进行多维度分析，实现上栗县在建工程的数字化管理，致力于成为县领导的项目监管助手、财政局的资金智能管家、监管单位的过程监管帮手。
      </p>
      <p class="ui-explain">角色权限说明：</p>
      <div class="ui-role">
        <img src="@/assets/image/welcome/role_authority.png" />
      </div>
      <p class="ui-explain">
        各单位需尽快完成本单位实施项目的资料录入，以丰富在建工程子系统项目基础数据。
      </p>
    </a-spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
};
</script>
<style scoped>
.ui-title {
  text-align: center;
  font-size: 35px;
}
.ui-childTitle {
  text-align: center;
  font-size: 30px;
}
.ui-content,
.ui-explain {
  font-size: 20px;
  text-indent: 2em;
}
.ui-role {
  text-align: center;
}
</style>